.noticeboxxx{
    background-color: #D2E0FB;
    box-shadow: 5px 5px 10px #000000;
}
.noticeboxxx h1{
    font-family: monospace;
    font-size: xx-large;
}
.noticeboxxx label{
    font-size: x-large;
}