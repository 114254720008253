.imagecontent {
    margin-top: 2%;
}

.imagebox {
    height: 90%;
    width: 90%;
    align-self: center;
}

.arrows {
    align-items: center;
}

.contentext {
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    margin-bottom: 2px;
}

.textcaption {
    text-align: center;
    font-size: 3rem;
    font-family: serif;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 10rem;
}

.carTop{
    margin-top: 6.8rem;
}

@media (max-width:768px) {
    .carTop {
        margin-top: 5.3rem;
    }

    .textcaption {
        margin-bottom: 0px;
        font-size: 1.5rem;
    }
}

@media (max-width:992px) {
    .textcaption {
        margin-bottom: 0px;
        font-size: 2rem;
    }
}