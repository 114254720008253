.footer{
    /* background-color: #696b6e; */
    background: linear-gradient(270deg, #292a2b, #4b4d4f);
}
.copyright{
    height: 25px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    text-align: center;
}
.links{
    padding-right: 2rem;
    text-decoration: none;
}
.follow{
    color: #ffffff;
   
}
.text h4{
    color: #ffffff;
}
.text p{
    margin: 0px;
}