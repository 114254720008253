.updatenoticebox{
 background-color: #dde25f;
}
.updatenoticebox h6{
    font-size: medium;
    font-family: serif;
    font-weight: 700;
}
.updatenoticebox p{
    font-size: small;
    font-family: monospace;
    font-weight: 600;
}
.headlist{
    font-family:cursive;
    margin-bottom: 5px;
}