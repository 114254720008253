body{
    background: linear-gradient(135deg,rgb(255, 255, 255),#e3f7fc);
}
.UpperMarginClass {
    margin-top: 6.8rem;
}

@media (max-width:768px){
    .UpperMarginClass{
        margin-top: 5rem;
    }
}
@media (max-width:992px){
    h1{
        font-size: medium;
    }
    p{
        font-size: small;
    }
}