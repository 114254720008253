.phototext{
    width: 25%;
    height: min-content;
    margin: 3%;
    margin-right: 5%;

}
.total{
    flex-wrap: wrap;
}
.frame{
    background-color: #d8c388;
    border: 20px solid #000000;
    margin-bottom: 20px;

}
.frame h1{
    color: #ffffff;
}
.pdfview img{    
    border-radius: 10px;
    border-bottom-right-radius: 70%;
}
.pdfview {
    border: 2px solid rgb(208, 81, 26);
    background: linear-gradient(90deg , rgb(208, 81, 26) , transparent ) ;
    border-radius: 10px;
}
.view_link{
    text-decoration: none;
    color: #000;
    text-align: end;
    font-weight: 600;
}
@media (max-width:768px){
    .view_link{
        font-size: xx-small;
        padding: 0px;
    }
}