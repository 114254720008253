.contentext {
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    margin-bottom: 2px;
}

.logo {
    height: 5rem;
    width: 5rem;
}

.whoarewe h1 {
    color: #fff;
    padding: 0.9rem;
    margin-bottom: 5px;
    margin-top: 5rem;
    border: 2px solid #e0401f;
    text-align: center;
    width: fit-content;
    background-color: #e0401f;
    border-radius: 2px;    
}
.program h1 {
    margin-bottom: 10px;
    color: #fff;
    border: 2px solid #e0401f;
    background-color: #e0401f;
    padding: 0.5rem;
    border-radius: 7px;
}
.collab{
    border: 2px solid #e0401f;
    padding: 0.5rem;
    background-color: #e0401f;
    color: #fff;
    border-radius: 7px;
}
.hoverbox {
    margin-bottom: 15px;
}


.programcontent {
    box-shadow: 5px 10px 10px rgb(102, 102, 102);
    padding: 0px;
    margin-bottom: 15px;
}

.programcontent p {
    text-align: justify;
}
.programcontent h2{
    margin-top: 0.5rem;
}

.programcontent button {
    background-color: #000000;
    color: #ffffff;
}
@media (max-width:768px){
    .whoarewe h1 {
        color: #fff;
        /* text-decoration: dashed; */
        padding: 0.5rem;
        margin-bottom: 5px;
        margin-top: 5rem;
        border: 2px solid #e0401f;
        text-align: center;
        width: fit-content;
        background-color: #e0401f;
        border-radius: 2px;    
    }
}

.whatsapp_button{
    text-align: start;
    margin-top: 2rem;
    margin-left: 1.5rem;
}
.whatsapp_button button {
    background-color: #25D366;
    border: 2px solid #25D366;
    border-radius: 7px;
    padding: 0.5rem;
    font-weight: 600;
}