.sbutton{
    background-color: #40A2D8;
    border: 2px solid #40A2D8;
    color: white;
    border-radius: 15px;
    font-size: larger;
    margin-top: 5px;
    margin-bottom: 5px;
    z-index: 1;
}
.headings{
    color: rgb(255, 255, 255);
    z-index: 1;
}
.contact{
    font-weight: 600;
    z-index: 1;
}
.contactusphoto::before{
    background-image: url('/src/components/images/contact\ us.jpg');
    background-size:cover;
    background-repeat: no-repeat;
    position: absolute;
    content: '';
    inset: 0;
    opacity: 0.6;
}
.contactusphoto{
    place-items: center;
    position: relative;
    background-color: #0000ff;
}
.contactusphoto h1{
    color: #ffffff;
}
#contacttext{
font-weight: 600;
}
@media (max-width:767px){
    #topcontent{
        margin-top: 14rem;
    }
}