.contentbox{
    background-color: #BBE2EC;
    box-shadow: 10px 10px 5px  #4475c0;
}
.contentbox h2{
    font-family:monospace;
}
.contentbox label{
    font-size: large;
    font-family: sans-serif;
}
.contentbox p{
    font-weight: 500;
    font-style: italic;
}