.infobox h5 {
    font-family: Cambria;
    font-weight: 600;
}
.infobox p {
    font-family: monospace;
    font-weight: 900;
    font-size: xx-large;
    color: rgb(3, 92, 35);

}
.infobox {
    box-shadow: 5px 10px 10px rgb(102, 102, 102);
    margin: 5px;
}
@media (max-width:768px){
    .infobox{
        margin-bottom: 5rem;
        margin-top: 1.5rem;
        margin-left: 0px;
        margin-right: 0px;
    }
}





