input[type=number]::-webkit-inner-spin-button{
    display: none;
}
.workbox{
    background-color: #BBE2EC;
    box-shadow: 10px 10px 5px #BBE2EC ;
    border: 2px solid #29bee3;
    border-radius: 10px;
}
.workbox h1{
    font-size:xx-large;
    font-family: monospace;
}
.workbox label{
    font-size: larger;
    font-family: sans-serif;
}