.infoboxlist h5{
    font-family:Cambria;
    font-weight: 600;
    font-size: x-large;
}
.infoboxlist p{
       font-family: monospace;
       font-weight: 500;
       font-size: x-large;
}
.infoboxlist{
    background-color: #719df0;
    color: white;
}