.founder {
    border: 3px solid #176B87;
    border-radius: 50%;
}

.design {
    background-color: rgb(240, 240, 255);
}

.design:hover {
    background-color: rgb(177, 182, 243);
    border: 4px solid transparent;
    border-image: linear-gradient(45deg, rgb(152, 96, 236), rgb(255, 255, 255));
    border-image-slice: 1;
}

.profile h3 {
    font-weight: 600;
}

.profile p {
    font-weight: 500;
}

.teambox {
    border: 15px solid salmon;
}

@media (max-width:767px){
    .divteam{
        margin-top:5rem;
    }
    
}
@media (max-width:575px){
    #teamcontent {
        font-size: 1rem;
    }
}