.newlogo{
    height: 5rem;
    margin-left: 2rem;
}
.newlogo :hover{
    border: 5px solid #1c1b19;
}
.navhead{
    padding-left: 0px;
    padding-right: 0px;
    gap: 0;
}
.navlink{
    margin-right: 15px;
    margin-top: 5px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #ffffff;
}
.dropdown-menu{
    background-color:white;
    border: 0px;
}
.dropdown-item{
    color: #ffffff;
}
.postionnav{
    background-color: white;
    box-shadow: 0px 10px 10px rgb(102, 102, 102);
}
.designchange{
    background-color:white;
    transition: .1s ease-in-out;
    /* rgb(238, 199, 89); */
}
.donateButton{
    visibility: hidden;
}
.name p{
    color: #ffffff;
    font-family: monospace;
    font-weight: 600;
    font-size: larger;
}
@media (max-width:768px){
    .navhead{
        display: flex;
        justify-content: center;
    }
    .donateButton{
        color: #ff0000;
        font-weight: 700;
        visibility: visible;
        margin-left: 5rem;
        background-color: #eec759;
        border: 2px solid #eec759;
        border-radius: 5px;
    }
    .newlogo{
        height: 3.5rem;
        margin-left: 0.5em;
    }
}

@media (max-width:390px){
    .donateButton{
        color: #ff0000;
        font-weight: 700;
        visibility: visible;
        margin-left: 3rem;
        background-color: #eec759;
        border: 2px solid #eec759;
        border-radius: 5px;
    }
}
@media (max-width:345px){
    .donateButton{
        color: #ff0000;
        font-weight: 700;
        visibility: visible;
        margin-left: 1.5rem;
        background-color: #eec759;
        border: 2px solid #eec759;
        border-radius: 5px;
    }
    .newlogo{
        height: 3rem;
        margin-left: 0.5em;
    }
}