.menubar{
    background-color: #4475c0;
    box-shadow: rgba(0, 0, 0, 0.5);
}
.buttonns{
    background-color: transparent;
    color: #000000;
    height: fit-content;
    font-size: larger;
}
.buttonns:hover{
    background-color: #ffffff;
}
.Links{
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-family:serif;
    font-size: larger;
}
.sidebar{
    background-color: #0D9276;
}