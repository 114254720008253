.noticebox h5{
    font-size: larger;
    color: #0000ff;
}
.noticebox p{
    font-size: small;
    color: #000000;
}
.noticebox{
    background-color: #FBF46D;
}
.cluster{
    margin-left: 5px;
}
.noticeboardname{
    padding-bottom: 10px;
}
.noticecontent{
    border: 20px solid #A1662F;
    background-color: rgb(235, 64, 64);
    margin-bottom: 10px;
}