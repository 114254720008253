.ruraltext{
    box-shadow: 5px 5px 10px #808080;
    border-radius: 10px;
    font-size: larger;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.missionrural {
    box-shadow: 5px 5px 10px #808080;
    border-radius: 10px;
}
.missionrural p{
    font-size: larger;
    font-weight: 500;
}
.visionrural{
    box-shadow: 5px 5px 10px #808080;
    border-radius: 10px;
    margin-bottom: 1rem;
}
.visionrural p{
    font-size: larger;
    font-weight: 500;
}
.ruralphoto::before{
    background-image: url('../images/Rural.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    content:'';
    position: absolute;
    inset: 0;
    opacity: 1;
}
.ruralphoto{
    width: 100%;
    height: 50vw;
    display: grid;
    justify-items: start;
    align-items: center;
    position: relative;
    background-color: #353434;
    margin-bottom: 0.5rem;
}
.ruralphoto h1{
    color: #ffffff;
    z-index: 1;
    margin-left: 10rem;
    text-align: center;
    font-size: 2rem;
}
.ruralacheive{
    box-shadow: 5px 5px 10px #808080;
    border-radius: 10px;
    margin-bottom: 1rem;
}
.ruralacheive p{
    font-size: larger;
    font-weight: 500;
}
.education{
    font-size: 5rem;
    color: #e0401f;
    font-weight: 700;
}

.rural{
    border: 2px solid #e0401f;
    width: fit-content;
    background-color: #e0401f;
    padding: 0.5rem;
    border-radius: 5px;
    color: #ffffff;
}


@media (max-width:768px){
    .education{
        font-size: 2rem;
        color: #e0401f;
        font-weight: 700;
    }
    .ruralphoto h1{
        color: #ffffff;
        z-index: 1;
        margin-left: 1rem;
        text-align: center;
        font-size: 0.9rem;
    }

}

@media (max-width:1024px) and (min-width:768px){
    .education{
        font-size: 3rem;
        color: #e0401f;
        font-weight: 700;
    }
    .ruralphoto h1{
        color: #ffffff;
        z-index: 1;
        margin-left: 1.5rem;
        text-align: center;
        font-size: 1.5rem;
    }

}