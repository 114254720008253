.maincontent{
    margin-top: 2%;
    margin-bottom: 5px;
    border: 2px solid rgb(53, 163, 231);
    margin-left: 5%;
    margin-right: 5%;
}
.maincontent img{
    height: 100%;
    width: 100%;
    align-self: center;
}

.maincontent p{
    color: #000000;
    text-align: center;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    font-size:large;
    margin-bottom: 2px;
}